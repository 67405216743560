import React from 'react'
import {
  ActiveModalProps,
  ModalConfig,
  RecursiveOmitSetActiveModalId
} from 'shared/library/molecules/Modal'
import SignIn, { SignedIn } from 'library/organisms/SignIn'
import SignOut from 'library/organisms/SignOut'
import SignUp from 'library/organisms/SignUp'
import { Translate } from 'next-translate'

/**
 * Core setup for inserting all necessary modals related to authenticating a user
 */

type SetActiveModalId = Pick<ActiveModalProps, 'setActiveModalId'>

export enum MODAL_IDS_AUTH {
  signIn = 'signIn',
  authenticated = 'authenticated',
  signOut = 'signOut',
  signUp = 'signUp'
}

export const MODAL_PROP_VARIATIONS_AUTH = {
  signIn: {},
  authenticated: {},
  signOut: {},
  signUp: {}
}

export type ModalAuthProps = RecursiveOmitSetActiveModalId<{
  [MODAL_IDS_AUTH.signIn]: null
  [MODAL_IDS_AUTH.authenticated]: null
  [MODAL_IDS_AUTH.signOut]: null
  [MODAL_IDS_AUTH.signUp]: null
}>

export const generateModalTitleAuth = (t: Translate) => ({
  [MODAL_IDS_AUTH.signIn]: t('authentication:sign-in'),
  [MODAL_IDS_AUTH.authenticated]: t('authentication:welcome-back'),
  [MODAL_IDS_AUTH.signOut]: t('authentication:sign-out'),
  [MODAL_IDS_AUTH.signUp]: t('authentication:sign-up')
})

export const MODAL_CONFIG_AUTH: ModalConfig = {
  [MODAL_IDS_AUTH.signIn]: {
    id: MODAL_IDS_AUTH.signIn,
    Content: ({
      setActiveModalId
    }: ModalAuthProps[MODAL_IDS_AUTH.signIn] & SetActiveModalId) => (
      <SignIn
        modalView
        onRequestToSignUp={() => setActiveModalId(MODAL_IDS_AUTH.signUp)}
      />
    )
  },
  [MODAL_IDS_AUTH.authenticated]: {
    id: MODAL_IDS_AUTH.authenticated,
    Content: ({
      setActiveModalId
    }: ModalAuthProps[MODAL_IDS_AUTH.authenticated] & SetActiveModalId) => (
      <SignedIn
        modalView
        onRequestToRedictToHome={() => setActiveModalId(null)}
        onSuccessToSignOut={() => setActiveModalId(null)}
      />
    )
  },
  [MODAL_IDS_AUTH.signOut]: {
    id: MODAL_IDS_AUTH.signOut,
    Content: ({
      setActiveModalId
    }: ModalAuthProps[MODAL_IDS_AUTH.signOut] & SetActiveModalId) => (
      <SignOut modalView onSuccess={() => setActiveModalId(null)} />
    )
  },
  [MODAL_IDS_AUTH.signUp]: {
    id: MODAL_IDS_AUTH.signUp,
    Content: ({
      setActiveModalId
    }: ModalAuthProps[MODAL_IDS_AUTH.signUp] & SetActiveModalId) => (
      <SignUp
        modalView
        onSuccess={() => setActiveModalId(null)}
        onRequestToSignIn={() => setActiveModalId(MODAL_IDS_AUTH.signIn)}
      />
    )
  }
}
