import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Text, H3 } from 'library/atoms/Typography'
import useTranslation from 'next-translate/useTranslation'
import * as Yup from 'yup'
import Trans from 'next-translate/Trans'
import { Form, Formik } from 'formik'
import Input from 'library/atoms/Input'
import Checkbox from 'library/atoms/Checkbox'
import FocusOnFormikErrors from 'library/atoms/FocusOnFormikErrors'
import Button from 'library/atoms/Button'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useEnthusiast } from 'shared/state/useAuth'
import InfoBar from 'library/molecules/InfoBar'
import Layout from 'library/atoms/Layout'
import Head from 'next/head'
import Header from 'library/molecules/Header'
import { BackBreadcrumb } from 'library/molecules/Breadcrumb'
import { LEGACY_EOLA_ROUTES } from 'enums/routes'
import { useRouter } from 'next/router'
import Flex from 'shared/library/atoms/Flex'
import widgetRoutes from 'enums/routes'

interface SignUp {
  onSuccess: () => void
  /**
   * This attribute is related to onSuccess. This is useful if onSuccess has a loading
   * state and we want to display it on the submit button.
   */
  onSuccessIsLoading?: boolean
  onRequestToSignIn: () => void
  primaryCta?: string
  secondaryCta?: string
  /**
   * By default the back button will return to the homepage. Add a different function in case
   * it has to be overwritten.
   */
  backBreadcrumbOnClick?: () => void
  modalView?: boolean
  view?: string
}

interface SignUpForm {
  name: string
  email: string
  receiveNews: boolean
  receiveOutletNews: boolean
  outletId: string
}

const spaceBetweenElements = 5

const SignUp = ({
  onSuccess,
  onSuccessIsLoading,
  onRequestToSignIn,
  primaryCta,
  secondaryCta,
  backBreadcrumbOnClick,
  modalView = false
}: SignUp) => {
  const { query, push, locale } = useRouter()
  const widgetSlug = query.widgetSlug as string
  const { t } = useTranslation('authentication')
  const { setAuthenticated } = useEnthusiast()
  const [errorMessage, setErrorMessage] = useState<null | string>()

  const errorInfoRef = useRef<HTMLDivElement>(null)

  const { data } = useQuery(WIDGET_OUTLET_SIGN_UP, {
    variables: { widgetSlug }
  })

  const signUpFormInitialValues = {
    name: '',
    email: '',
    receiveNews: false,
    receiveOutletNews: false,
    outletId: data?.widgets[0]?.outlet?.id
  }

  const [signIn, { loading: signUpLoading }] = useMutation(SIGN_UP, {
    onCompleted({ Consumer_Sessions_SignUp }) {
      if (!Consumer_Sessions_SignUp?.message) {
        return
      }
      setErrorMessage(null)
      setAuthenticated(true)
      onSuccess ? onSuccess() : push(widgetRoutes.HOME.url({ widgetSlug }))
    },
    onError(error) {
      setErrorMessage(error.message)
      if (!error || !errorInfoRef?.current) {
        return
      }
      errorInfoRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  })

  const onSubmit = async (values: SignUpForm) => {
    const signInVars = {
      variables: {
        name: values.name,
        email: values.email,
        subscribedToMarketplaceEmails: values.receiveNews,
        subscribedToOutletEmails: values.receiveOutletNews,
        outletId: values.outletId,
        preferredLocale: locale
      }
    }

    await signIn(signInVars)
  }

  const handleRequestToSignIn = () => {
    !!onRequestToSignIn && onRequestToSignIn()
  }

  const requiredMessage = t('common:required')
  const SignUpFormValidateSchema = Yup.object().shape({
    name: Yup.string().required(requiredMessage),
    email: Yup.string().email().required(requiredMessage)
  })

  return (
    <>
      <Head>
        <title>{t('create-an-account')}</title>
      </Head>
      <Layout>
        {!modalView && (
          <>
            <Header />
            <BackBreadcrumb
              title={t('create-an-account')}
              href={widgetRoutes.HOME.url({ widgetSlug })}
              onClick={backBreadcrumbOnClick}
            />
          </>
        )}
        <main>
          <StyledCTA>
            <Text fontStyle="h3" as="span">
              🤙
            </Text>
            <H3>{primaryCta ?? t('create-an-account')}</H3>
            <Text color="secondary">
              {secondaryCta ?? t('create-account-cta')}
            </Text>
          </StyledCTA>
          {/**
           *
           * Sign Up Form
           *
           */}
          <Formik
            initialValues={signUpFormInitialValues}
            onSubmit={onSubmit}
            validationSchema={SignUpFormValidateSchema}
          >
            {({ handleSubmit, isValid, dirty }) => (
              <StyledForm>
                <Input type={'hidden'} name={'outletId'} />
                <Input label={t('name')} name={'name'} />
                <Input label={t('email-address')} name={'email'} type="email" />
                {widgetSlug && data?.widgets[0]?.outlet?.name && (
                  <Checkbox
                    label={t('receiveOutletNews', {
                      outlet: data?.widgets[0]?.outlet?.name
                    })}
                    name={'receiveOutletNews'}
                    mt={5}
                  />
                )}
                <Checkbox
                  label={t('receiveEolaNews')}
                  name={'receiveNews'}
                  mt={5}
                />
                <FocusOnFormikErrors />
                {errorMessage && (
                  <InfoBar
                    icon="info"
                    variant="error"
                    size="small"
                    ref={errorInfoRef}
                    mt={spaceBetweenElements}
                  >
                    {errorMessage}
                  </InfoBar>
                )}
                <StyledButton
                  type="submit"
                  onClick={handleSubmit}
                  inactive={!(isValid && dirty)}
                  loading={signUpLoading || onSuccessIsLoading}
                >
                  {t('sign-up')}
                </StyledButton>
                <Text textAlign="center" mb={5}>
                  {/**
                   * Exception: using Trans instead of useTranslation.
                   * Reason: using HTML inside the translation.
                   */}
                  <Trans
                    i18nKey="authentication:agree-to-terms"
                    components={[
                      <StyledTermsButton
                        variant="tertiary"
                        as="a"
                        key="terms-and-conditions"
                        href={LEGACY_EOLA_ROUTES.TERMS_CONDITIONS.url()}
                      />,
                      <StyledTermsButton
                        variant="tertiary"
                        as="a"
                        key="privacy-policy"
                        href={LEGACY_EOLA_ROUTES.PRIVACY_POLICY.url()}
                      />
                    ]}
                  />
                </Text>
              </StyledForm>
            )}
          </Formik>
          <Flex flexDirection="column" alignItems="center">
            <Text>{t('question-already-have-account')}</Text>
            <Button variant="tertiary" as="a" onClick={handleRequestToSignIn}>
              {t('sign-in')}
            </Button>
          </Flex>
        </main>
      </Layout>
    </>
  )
}

export default SignUp

export const SIGN_UP = gql`
  mutation WidgetSignUp(
    $name: String!
    $email: String!
    $subscribedToMarketplaceEmails: Boolean!
    $subscribedToOutletEmails: Boolean!
    $outletId: ID
    $preferredLocale: String!
  ) {
    Consumer_Sessions_SignUp(
      name: $name
      email: $email
      subscribed_to_marketplace_emails: $subscribedToMarketplaceEmails
      subscribed_to_outlet_emails: $subscribedToOutletEmails
      outlet_id: $outletId
      preferred_locale: $preferredLocale
    ) {
      message
    }
  }
`

export const WIDGET_OUTLET_SIGN_UP = gql`
  query WidgetOutletSignUp($widgetSlug: String!) {
    widgets(where: { slug: { _eq: $widgetSlug } }) {
      outlet {
        id
        name
      }
    }
  }
`

const StyledTermsButton = styled(Button)(() => ({
  display: 'inline'
}))

const StyledCTA = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.space[5]
}))

const StyledForm = styled(Form)(({ theme }) => ({
  marginTop: theme.space[5],
  marginBottom: theme.space[5],
  padding: theme.space[4],
  background: theme.colors.white,
  borderRadius: theme.radii[3],
  boxShadow: theme.shadows.primary[2],
  ...theme.mixins.centeredContainer('sm')
}))

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.space[5],
  marginBottom: theme.space[5]
}))
