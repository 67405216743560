import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'

const ConditionalMarketplace = ({
  shown,
  children
}: {
  shown: boolean
  children: ReactNode
}) => {
  const { query } = useRouter()
  const [isMarketplaceDomain, setIsMarketplaceDomain] = useState(false)

  let source = query.utm_source || ''
  // This can be a string[] for some reason
  source =
    typeof source === 'string' ? source.toLowerCase() : source[0].toLowerCase()

  const isMarketplaceUTM = source === 'marketplace'

  useEffect(() => {
    setIsMarketplaceDomain(!window?.location?.hostname?.startsWith('widget.'))
  }, [])

  const isMarketplace = isMarketplaceUTM || isMarketplaceDomain

  if (shown) return isMarketplace ? <>{children}</> : <></>
  else return isMarketplace ? <></> : <>{children}</>
}

/**
 * This component conditionally shows its children if on the marketplace.
 */
export const ShowOnMarketplace = ({ children }: { children: ReactNode }) => (
  <ConditionalMarketplace shown={true}>{children}</ConditionalMarketplace>
)

/**
 * This component conditionally hides its children if on the marketplace.
 */
export const HideOnMarketplace = ({ children }: { children: ReactNode }) => (
  <ConditionalMarketplace shown={false}>{children}</ConditionalMarketplace>
)
