import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useEnthusiast } from 'shared/state/useAuth'

interface UseSignOut {
  onSuccess: () => void
}

/**
 * It calls a mutation to sign out the user.
 */
const useSignOut = ({ onSuccess }: UseSignOut) => {
  const { setAuthenticated } = useEnthusiast()
  const [errorMessage, setErrorMessage] = useState<undefined | string>()
  const [signOut, { loading: signOutLoading }] = useMutation(SIGN_OUT, {
    onCompleted({ Consumer_Sessions_SignOut }) {
      if (!Consumer_Sessions_SignOut?.message) {
        return
      }
      setAuthenticated(false)
      onSuccess && onSuccess()
    },
    onError(error) {
      setErrorMessage(error.message)
    }
  })

  return {
    signOut,
    signOutLoading,
    signOutError: errorMessage
  }
}

export default useSignOut

export const SIGN_OUT = gql`
  mutation WidgetUseSignOut {
    Consumer_Sessions_SignOut {
      message
    }
  }
`
