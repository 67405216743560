import { useContext } from 'react'
import { BasketContext, BasketContextProps } from 'providers/BasketProvider'

/**
 * It uses the context from BasketProvider to render the basket data.
 */
const useBasket = (): BasketContextProps => {
  const context = useContext(BasketContext)
  if (context === undefined) {
    throw new Error('useBasket must be used within an BasketProvider')
  }

  return context
}

export default useBasket
