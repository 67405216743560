import React, { useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import widgetRoutes from 'enums/routes'
import { Form, Formik } from 'formik'
import Layout from 'library/atoms/Layout'
import { Text, H3 } from 'library/atoms/Typography'
import { useRouter } from 'next/router'
import * as Yup from 'yup'
import Button from 'library/atoms/Button'
import Input from 'library/atoms/Input'
import Checkbox from 'library/atoms/Checkbox'
import useTranslation from 'next-translate/useTranslation'
import InfoBar from 'library/molecules/InfoBar'
import Flex from 'shared/library/atoms/Flex'
import Head from 'next/head'
import Header from 'library/molecules/Header'
import { BackBreadcrumb } from 'library/molecules/Breadcrumb'
import FocusOnFormikErrors from 'library/atoms/FocusOnFormikErrors'

interface SignIn {
  onRequestToSignUp: () => void
  /**
   * By default the back button will return to the homepage. Add a different function in case
   * it has to be overwritten.
   */
  backBreadcrumbOnClick?: () => void
  modalView?: boolean
  view?: string
  customRedirectPath?: string
}

interface SignInForm {
  email: string
  rememberMe: boolean
  redirectTo: string
}

export const SIGN_IN_FORM_INITIAL_VALUES: SignInForm = {
  email: '',
  rememberMe: false,
  redirectTo: ''
}

const spaceBetweenElements = 5

const SignIn = ({
  onRequestToSignUp,
  backBreadcrumbOnClick,
  modalView = false,
  customRedirectPath
}: SignIn) => {
  const { locale, query } = useRouter()
  const widgetSlug = query.widgetSlug as string
  const { t } = useTranslation('authentication')
  const [errorMessage, setErrorMessage] = useState<null | string>()
  const [magicLinkSent, setMagicLinkSent] = useState<boolean>(false)

  const errorInfoRef = useRef<HTMLDivElement>(null)

  // If no custom redirect path supplied, redirect to current page on following magic link.
  const redirectTo = customRedirectPath
    ? window.location.origin + customRedirectPath
    : window.location.href

  const [signIn, { loading: signInLoading }] = useMutation(SIGN_IN, {
    onCompleted({ Consumer_Sessions_SignIn }) {
      if (!Consumer_Sessions_SignIn?.message) {
        return
      }

      setErrorMessage(null)
      setMagicLinkSent(true)
    },
    onError(error) {
      setErrorMessage(error.message)
      if (!error || !errorInfoRef?.current) {
        return
      }
      errorInfoRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  })

  const SignInFormValidateSchema = Yup.object().shape({
    email: Yup.string().required(t('common:required')),
    rememberMe: Yup.boolean().required(t('common:required'))
  })

  const onSubmit = async (values: SignInForm) => {
    const signInVars = {
      variables: {
        email: values.email,
        rememberMe: values.rememberMe,
        redirectTo: redirectTo,
        preferredLocale: locale
      }
    }

    await signIn(signInVars)
  }

  const handleRequestToSignUp = () => {
    !!onRequestToSignUp && onRequestToSignUp()
  }

  return (
    <>
      <Head>
        <title>{t('sign-in')}</title>
      </Head>
      <Layout>
        {!modalView && (
          <>
            <Header />
            <BackBreadcrumb
              title={t('sign-in')}
              href={widgetRoutes.HOME.url({ widgetSlug })}
              onClick={backBreadcrumbOnClick}
            />
          </>
        )}
        <main>
          <StyledContainer>
            <Flex flexDirection="column" alignItems="center">
              <Text fontStyle="h3" as="span">
                🔐
              </Text>
              <H3>{t('sign-in')}</H3>
              <Text textAlign="center" color="secondary">
                {t('about-sign-in')}
              </Text>
            </Flex>
            {/**
             *
             * Sign In Form
             *
             */}
            <Formik
              initialValues={SIGN_IN_FORM_INITIAL_VALUES}
              onSubmit={onSubmit}
              validationSchema={SignInFormValidateSchema}
            >
              {({ handleSubmit, isValid, dirty }) =>
                magicLinkSent ? (
                  <StyledForm>
                    <Text color="secondary" textAlign="center">
                      {t('magic-link-sent')}
                    </Text>
                  </StyledForm>
                ) : (
                  <StyledForm>
                    <Input type="email" label={t('email')} name={'email'} />
                    <FocusOnFormikErrors />
                    {errorMessage && (
                      <InfoBar
                        icon="info"
                        variant="error"
                        size="small"
                        ref={errorInfoRef}
                        mt={spaceBetweenElements}
                      >
                        {errorMessage}
                      </InfoBar>
                    )}
                    <Checkbox
                      label={t('remember-me')}
                      name={'rememberMe'}
                      mt={spaceBetweenElements}
                      mb={0}
                    />
                    <StyledButton
                      type="submit"
                      onClick={handleSubmit}
                      inactive={!(isValid && dirty)}
                      loading={signInLoading}
                    >
                      {t('send-magic-link')}
                    </StyledButton>
                  </StyledForm>
                )
              }
            </Formik>
            {!!onRequestToSignUp && (
              <Flex flexDirection="column" alignItems="center">
                <Text color="secondary">{t('question-have-account')}</Text>
                <Button
                  variant="tertiary"
                  as="a"
                  onClick={handleRequestToSignUp}
                >
                  {t('sign-up')}
                </Button>
              </Flex>
            )}
          </StyledContainer>
        </main>
      </Layout>
    </>
  )
}

export default SignIn

export const SIGN_IN = gql`
  mutation WidgetSignIn(
    $email: String!
    $rememberMe: Boolean!
    $preferredLocale: String!
    $redirectTo: String!
  ) {
    Consumer_Sessions_SignIn(
      email: $email
      remember_me: $rememberMe
      preferred_locale: $preferredLocale
      redirect_to: $redirectTo
    ) {
      message
    }
  }
`

const StyledContainer = styled.div(({ theme }) => ({
  ...theme.mixins.centeredContainer('sm')
}))

const StyledForm = styled(Form)(({ theme }) => ({
  marginTop: theme.space[5],
  marginBottom: theme.space[5],
  padding: theme.space[4],
  background: theme.colors.white,
  borderRadius: theme.radii[3],
  boxShadow: theme.shadows.primary[2]
}))

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.space[spaceBetweenElements]
}))
