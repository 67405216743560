import React from 'react'
import styled from '@emotion/styled'
import widgetRoutes from 'enums/routes'
import Layout from 'library/atoms/Layout'
import { Text } from 'library/atoms/Typography'
import { useRouter } from 'next/router'
import Button from 'library/atoms/Button'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import Header from 'library/molecules/Header'
import { BackBreadcrumb } from 'library/molecules/Breadcrumb'
import useSignOut from 'state/useSignOut'
import useBasket from 'state/useBasket'
import { AnimatedIcon } from 'shared/library/atoms/Icon'

interface SignOut {
  onSuccess: () => void
  modalView?: boolean
}

const SignOut = ({ modalView = false, onSuccess }: SignOut) => {
  const { query, push } = useRouter()
  const widgetSlug = query.widgetSlug as string
  const { t } = useTranslation('authentication')
  const { signOut } = useSignOut({ onSuccess })
  const { basket, refetchBasket } = useBasket()

  const hasToClearBasket =
    basket?.hasLineItemBookingForMembersOnly() ||
    basket?.hasMembershipSubscription()

  const signOutOnClick = async () => {
    if (hasToClearBasket) {
      await signOut()
      await push(widgetRoutes.HOME.url({ widgetSlug }))
      refetchBasket({ basketId: null })
    } else {
      signOut()
    }
  }

  return (
    <>
      <Head>
        <title>{t('sign-out')}</title>
      </Head>
      <Layout>
        {!modalView && (
          <>
            <Header />
            <BackBreadcrumb
              title={t('sign-out')}
              href={widgetRoutes.HOME.url({ widgetSlug })}
            />
          </>
        )}
        <main>
          {hasToClearBasket && (
            <StyledContainer>
              <AnimatedIcon name="exclamation" size={64} autoplay />
              <Text color="secondary">
                {t(
                  basket?.hasLineItemBookingForMembersOnly()
                    ? 'sign-out-with-members-only'
                    : 'sign-out-with-membership'
                )}
              </Text>
            </StyledContainer>
          )}

          <StyledContainer>
            <Text color="secondary">{t('are-you-finished')}</Text>
            <Button variant="tertiary" as="a" onClick={signOutOnClick}>
              {t('sign-out')}
            </Button>
          </StyledContainer>
        </main>
      </Layout>
    </>
  )
}

export default SignOut

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  marginTop: theme.space[5],
  marginBottom: theme.space[5],
  padding: `${theme.space[6]}px ${theme.space[4]}px`,
  background: theme.colors.white,
  borderRadius: theme.radii[3],
  boxShadow: theme.shadows.primary[2],
  ...theme.mixins.centeredContainer('sm'),
  '&:first-of-type': {
    marginBottom: 0,
    p: {
      marginTop: theme.space[4]
    }
  }
}))
