import React from 'react'
import styled from '@emotion/styled'
import widgetRoutes from 'enums/routes'
import Layout from 'library/atoms/Layout'
import { Text, H3 } from 'library/atoms/Typography'
import { useRouter } from 'next/router'
import Button from 'library/atoms/Button'
import useTranslation from 'next-translate/useTranslation'
import Flex from 'shared/library/atoms/Flex'
import Head from 'next/head'
import Header from 'library/molecules/Header'
import { BackBreadcrumb } from 'library/molecules/Breadcrumb'
import useSignOut from 'state/useSignOut'

const spaceBetweenElements = 5

interface SignIn {
  /**
   * If this is not declared the router will redirect the view to the home page.
   * Oterwise, it also accept a funtion to overwrite it in case the page is a modal in the homepage.
   */
  onRequestToRedictToHome?: () => void
  onSuccessToSignOut: () => void
  modalView?: boolean
}

const SignedIn = ({
  onRequestToRedictToHome,
  onSuccessToSignOut,
  modalView = false
}: SignIn) => {
  const { query, push } = useRouter()
  const widgetSlug = query.widgetSlug as string
  const { t } = useTranslation('authentication')
  const { signOut } = useSignOut({ onSuccess: onSuccessToSignOut })

  return (
    <>
      <Head>
        <title>{t('welcome-back')}</title>
      </Head>
      <Layout>
        {!modalView && (
          <>
            <Header />
            <BackBreadcrumb
              title={t('welcome-back')}
              href={widgetRoutes.HOME.url({ widgetSlug })}
            />
          </>
        )}
        <main>
          <StyledContainer>
            <Text fontStyle="h3" as="span">
              👋
            </Text>
            <H3>{t('welcome-back')}</H3>
            <Text color="secondary">{t('you-are-signed-in')}</Text>
            <Button
              onClick={() =>
                onRequestToRedictToHome
                  ? onRequestToRedictToHome()
                  : push(widgetRoutes.HOME.url({ widgetSlug }))
              }
              mt={spaceBetweenElements}
            >
              {t('lets-book-something')}
            </Button>
            {/**
             * 
             * TODO - Waiting to introuce this button once the dashboard has been created.
             * 
            <Button variant="secondary" mt={spaceBetweenElements}>
              {t('go-to-my-dashboard')}
            </Button>
            **/}
          </StyledContainer>
          <Flex flexDirection="column" alignItems="center">
            <Text color="secondary">{t('are-you-finished')}</Text>
            <Button variant="tertiary" as="a" onClick={signOut}>
              {t('sign-out')}
            </Button>
          </Flex>
        </main>
      </Layout>
    </>
  )
}

export default SignedIn

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.space[5],
  marginBottom: theme.space[5],
  padding: `${theme.space[6]}px ${theme.space[4]}px`,
  background: theme.colors.white,
  borderRadius: theme.radii[3],
  boxShadow: theme.shadows.primary[2],
  ...theme.mixins.centeredContainer('sm')
}))
