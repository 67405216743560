import Menu, { MenuProps } from 'library/organisms/Menu'
import Modal, {
  ActiveModalProps,
  ModalConfig,
  MODAL_MIXINS,
  RecursiveOmitSetActiveModalId
} from 'shared/library/molecules/Modal'
import styled from '@emotion/styled'
import Button from 'library/atoms/Button'
import Icon from 'shared/library/atoms/Icon'
import {
  StyledHeader,
  StyledHeaderIconContainer,
  CLOSE_MENU_BUTTON_TESTID
} from './Header'
import useTranslation from 'next-translate/useTranslation'
import { generateModalTitleAuth, ModalAuthProps } from '../ModalsAuth'
import { StyledLayout } from 'library/atoms/Layout'
import SignIn, { SignedIn } from 'library/organisms/SignIn'
import SignOut from 'library/organisms/SignOut'
import SignUp from 'library/organisms/SignUp'

export enum MODAL_IDS {
  menu = 'menu',
  basket = 'basket',
  signIn = 'headerSignIn',
  authenticated = 'headerAuthenticated',
  signOut = 'headerSignOut',
  signUp = 'headerSignUp'
}

type SetActiveModalId = Pick<ActiveModalProps, 'setActiveModalId'>

export type ModalProps = RecursiveOmitSetActiveModalId<{
  [MODAL_IDS.menu]: MenuProps
  [MODAL_IDS.signIn]: ModalAuthProps['signIn']
  [MODAL_IDS.authenticated]: ModalAuthProps['authenticated']
  [MODAL_IDS.signOut]: ModalAuthProps['signOut']
  [MODAL_IDS.signUp]: ModalAuthProps['signUp']
}>

export const MODAL_CONFIG: ModalConfig = {
  [MODAL_IDS.menu]: {
    id: MODAL_IDS.menu,
    Content: Menu,
    clickOutsideToClose: true
  },
  [MODAL_IDS.signIn]: {
    id: MODAL_IDS.signIn,
    Content: ({
      setActiveModalId
    }: ModalAuthProps['signIn'] & SetActiveModalId) => (
      <SignIn
        modalView
        onRequestToSignUp={() => setActiveModalId(MODAL_IDS.signUp)}
      />
    )
  },
  [MODAL_IDS.authenticated]: {
    id: MODAL_IDS.authenticated,
    Content: ({
      setActiveModalId
    }: ModalAuthProps['authenticated'] & SetActiveModalId) => (
      <SignedIn
        modalView
        onRequestToRedictToHome={() => setActiveModalId(null)}
        onSuccessToSignOut={() => setActiveModalId(null)}
      />
    )
  },
  [MODAL_IDS.signOut]: {
    id: MODAL_IDS.signOut,
    Content: ({
      setActiveModalId
    }: ModalAuthProps['signOut'] & SetActiveModalId) => (
      <SignOut modalView onSuccess={() => setActiveModalId(null)} />
    )
  },
  [MODAL_IDS.signUp]: {
    id: MODAL_IDS.signUp,
    Content: ({
      setActiveModalId
    }: ModalAuthProps['signUp'] & SetActiveModalId) => (
      <SignUp
        modalView
        onSuccess={() => setActiveModalId(null)}
        onRequestToSignIn={() => setActiveModalId(MODAL_IDS.signIn)}
      />
    )
  }
}

/**
 * Please refer to https://www.notion.so/eola/Modals-new-widget-d5a5b2c100c840878761cd28bb3279a2
 * for an explanation on our approach to Modals
 */
const HeaderModals = ({
  activeModal,
  setActiveModalId,
  ...props
}: Omit<ActiveModalProps, 'activeModalId'>) => {
  const { t } = useTranslation()

  const MODAL_TITLE = {
    [MODAL_IDS.menu]: t('common:menu'),
    ...generateModalTitleAuth(t)
  }

  const ModalContent = activeModal?.Content ? activeModal?.Content : () => null

  const ModalTitle = activeModal?.id
    ? MODAL_TITLE[activeModal?.id as keyof typeof MODAL_TITLE]
    : undefined

  const ModalOnClickOutside = activeModal?.clickOutsideToClose
    ? () => setActiveModalId(null)
    : undefined

  return (
    <StyledModal
      isOpen={!!activeModal}
      activeModalId={activeModal?.id}
      animationVariant="rightToLeft"
      fullWindow
      modalTitle={ModalTitle}
      onClickOutside={ModalOnClickOutside}
      setActiveModalId={setActiveModalId}
    >
      <StyledHeader>
        <StyledHeaderIconContainer>
          <Button
            data-testid={CLOSE_MENU_BUTTON_TESTID}
            aria-label={t('common:close')}
            variant="icon"
            onClick={() => setActiveModalId(null)}
          >
            <Icon size={24} name="close" color="grey" />
          </Button>
        </StyledHeaderIconContainer>
      </StyledHeader>
      <ModalContent {...props} setActiveModalId={setActiveModalId} />
    </StyledModal>
  )
}

export default HeaderModals

const StyledModal = styled(Modal)(
  ({ theme }) => ({
    ...MODAL_MIXINS.stickToRight(),
    '[role="dialog"]': {
      background: theme.colors.background.secondary
    }
  }),
  ({ theme, activeModalId }) =>
    activeModalId === 'menu'
      ? {
          '[role="dialog"]': {
            right: 0,
            margin: 0,
            maxWidth: theme.contentMaxWidth.xs,
            main: {
              padding: `${theme.space[6]}px ${theme.space[4]}px`
            },
            [theme.mediaQueries.widget]: {
              /**
               * Use the max-width of the widget as a reference to position the element
               * instead of letting it be fixed to the right side of the window.
               */
              right: `calc(50vw - ${theme.contentMaxWidth.xl / 2}px)`
            }
          }
        }
      : {
          '[role="dialog"]': {
            margin: 'auto',
            [theme.mediaQueries.widget]: {
              [`${StyledLayout}`]: {
                border: 'none'
              }
            }
          }
        }
)
